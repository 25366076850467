import styled, { css } from 'styled-components'
import React, { useEffect, useState } from 'react'

import ItemsCarousel from 'react-items-carousel'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const Wrapper = styled.div`
  ${({ isDescriptionScreen }) =>
    !isDescriptionScreen
      ? css`
          /* padding: 30px 100px 30px 100px; */
        `
      : css`
          background-color: white;
          @media only screen and (max-width: 1024px) {
            @media (orientation: landscape) {
              margin-top: -64px;
              /* color: white !important; */
            }
          }
        `}
`

export const ButtonPrimary = styled.div`
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    border-radius: 3px;

    background-color: ${({ isDisabled }) => (isDisabled ? 'grey' : '#00095b')};
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    color: #fff !important;
    text-decoration: none;

    font-family: 'Ford Antenna Cond';
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 24px;

    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    &:hover {
      ${({ isDisabled }) => {
        if (!isDisabled) {
          return css`
            background-color: #1700f4;
            border-color: #1700f4;
            color: #fff;
            transform: scale(1.07);
          `
        }
      }}
    }

    .fds-icon {
      font-size: 22px;
    }
  }
`

const WrapperControls = styled.div`
  height: 40px;
  margin: 0 auto;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${({ isDescriptionScreen }) =>
    isDescriptionScreen &&
    css`
      @media only screen and (max-width: 1024px) {
        @media (orientation: portrait) {
          text-align: center;
          position: absolute;
          bottom: 42px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
        }

        @media (orientation: landscape) {
          margin-bottom: 42px;
          padding-bottom: 42px;
          display: none;
        }
      }
      @media only screen and (max-width: 635px) {
        bottom: 31px;

        @media (orientation: landscape) {
          padding-bottom: 31px;
        }
      }
      @media only screen and (min-width: 820px) and (max-width: 1024px) {
        @media (orientation: landscape) {
          text-align: center;
          position: absolute;
          bottom: 42px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
      }
    `}
`

const NumberDisplay = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
  color: #4d4d4d;
  letter-spacing: 1px;
`

const LabelCarousel = styled.h5`
  margin: 30px 20px 35px 20px !important;
  font-size: 32px !important;
  line-height: 38px;
  font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;

  ${({ isDescriptionScreen }) =>
    isDescriptionScreen &&
    css`
      @media only screen and (max-width: 1024px) {
        margin-left: 63px !important;

        @media (orientation: landscape) {
          display: none;
          /* color: white !important; */
        }
      }

      @media only screen and (max-width: 635px) {
        margin: 30px 31px 35px 31px !important;
      }
    `}
`

const Slider = ({ activeItemIndex, setActiveItemIndex, ...props }) => {
  // const [activeItemIndex, setActiveItemIndex] = useState(props.selectedSlide || 0)
  const breakpoints = useBreakpoint()

  const [maxLength, setMaxLength] = useState(
    Array.isArray(props.children[0]) ? props.children[0].length - 1 : props.children.filter(c => !!c).length - 1
  )

  const goToNextSlide = () => {
    if (activeItemIndex < maxLength) {
      setActiveItemIndex(state => state + 1)
    }
  }

  const goToBackSlide = () => {
    if (activeItemIndex > 0) {
      setActiveItemIndex(state => state - 1)
    }
  }

  useEffect(() => {
    setActiveItemIndex(props.selectedSlide || 0)
  }, [])

  useEffect(() => {
    if (activeItemIndex > maxLength) {
      setActiveItemIndex(maxLength)
    }
    if (activeItemIndex < 0) {
      setActiveItemIndex(0)
    }
  }, [activeItemIndex])

  const defaultSliders = props.slides || (breakpoints.st ? 1 : breakpoints.md ? 2 : 3)

  return (
    <Wrapper isDescriptionScreen={props.isDescriptionScreen}>
      <ItemsCarousel
        infiniteLoop={false}
        gutter={12}
        activePosition={'center'}
        chevronWidth={0}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={defaultSliders}
        slidesToScroll={1}
        firstAndLastGutter={true}
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        outsideChevron
        showSlither={props.noSlither ? false : breakpoints.sm ? true : breakpoints.md ? true : false}
        rightChevron={<></>}
        leftChevron={<></>}
      >
        {props.children}
      </ItemsCarousel>

      {props.label && <LabelCarousel isDescriptionScreen={props.isDescriptionScreen}>{props.label}</LabelCarousel>}

      <WrapperControls isDescriptionScreen={props.isDescriptionScreen}>
        <ButtonPrimary isDisabled={activeItemIndex === 0} onClick={goToBackSlide}>
          <a style={{ padding: '4px' }}>
            <span className="fds-icon">
              <span className="fds-font--ford-icons__chevron-left"></span>
            </span>
          </a>
        </ButtonPrimary>
        <div>
          <NumberDisplay>
            {activeItemIndex + 1}/{maxLength + 1}
          </NumberDisplay>
        </div>
        <ButtonPrimary isDisabled={activeItemIndex === maxLength} onClick={goToNextSlide}>
          <a style={{ padding: '4px' }}>
            <span className="fds-icon fds-color__text--dk-white">
              <span className="fds-font--ford-icons__chevron-right"></span>
            </span>
          </a>
        </ButtonPrimary>
      </WrapperControls>
    </Wrapper>
  )
}

export default Slider
